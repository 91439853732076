#dance-event-list {
    width: 700px;
    display: inline-block;
    margin-right: 30px;
    margin-top: 130px;
}

#dance-event-list a {
    color: #000000;
    text-decoration: none;
}

.event-list-row td {
    background: #efefef;
}

.event-table-list {

}

#dance-event-list .p-datatable-scrollable .p-datatable-tbody>tr>td {
    padding-top: 5px !important;
    padding-bottom: 10px !important;
    border-bottom: 1px dashed #cccccc !important;
    vertical-align: top;
}

#dance-event-list .duration {
    width: 45px;
    margin-left: 15px;
}

#dance-event-list .duration-week {
    margin-top: -7px;
    margin-left: 15px;
}

#dance-event-list .duration-week .week {
    height: 8px;
}

#dance-event-list .mb-2 {
    margin-bottom: 2px !important;
}

#dance-event-list span.p-rowgroup-header-name {
    display: inline-block;
}

#dance-event-list .title-container {
    padding-right: 0px;
}

#dance-event-list .p-rowgroup-header > td > span {
    padding-bottom: 5px !important;
    padding-top: 30px !important;
    padding-left: 15px;
}

#dance-event-list .teachers-container .p-chip {
    color: #ffffff;
    background: #777777;
}

#dance-event-list .teachers {
    margin-right: 15px;
}

#dance-event-list .camp-icon {
    margin-right: 5px;
    vertical-align: middle;
}

#dance-event-list .camp-icon .MuiSvgIcon-root {
    height: 18px;
    width: 18px;
}

#dance-event-list .live-music {
    margin-right: 15px;
}

#dance-event-list .live-music .p-chip {
    background-color: #5c9dd9;
}

#dance-event-list .teachers-container {
    margin-top: 3px;
}

#dance-event-list .teachers-container .location {
    margin-top: 3px;
}

#dance-event-list .live-music .p-chip {
    background: none;
    border: 1px solid #000000;
    color: #000000;
    font-size: 10px;
    margin-top:2px;
    margin-bottom: 0px;
    margin-right: 3px !important;
    padding: 0px 3px;
    padding-right: 9px;
    font-weight: bold;
}

#dance-event-list .p-tag-contrast {
    font-size: 9px;
    background: #cccccc;
}
