#map-holder {
  max-width: 100%;
  min-width: 100%;
  height: 100%;
  width: 100%;
}

.event-map-tooltip {
    padding: 3px 5px;
}

.event-map-tooltip .title {
    font-size: 13px;
    font-weight: bold;
    border-bottom: 1px dashed #cccccc;
    margin-bottom: 5px;
    padding-bottom: 5px;
}

.event-map-tooltip .duration {
    font-size: 11px;
}

.event-map-tooltip .p-chip {
  font-size: 10px;
  margin-bottom: 0px;
  margin-top: 3px;
  margin-right: 3px !important;
  padding: 0px 5px;
  font-weight: bold;
}