.filter-short-holder {
  width: 700px;
  margin: 0px auto;
  background-color: #ffffff;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px 20px;
  text-align: left;
}

#filter-short-holder {
    width: 700px;
    height: 100px;
    max-width: 700px;
    display: inline-block;
    margin-right: 30px;
    padding-top: 50px;
    position: absolute;
    min-height: 100px;
    text-align: left;
}

#filter-holder {
  max-width: 1350px;
  min-width: 1350px;
  margin: 0px auto;
  background-color: #ffffff;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px 20px;
  text-align: left;
}

.filter-row {
  margin-bottom: 10px;
}

.filter-row .arrow-icon {
  margin-left: 10px;
  margin-right: 10px;
}

.filter-row .p-inputtext {
  height: 30px;
  font-size: 12px;
  padding: 2px 10px 2px 30px;
  border: none;
  width: 275px;
}

.filter-row .p-checkbox .p-checkbox-box {
    height: 16px;
    width: 16px;
}

.filter-row label {
    margin-left: 5px;
    margin-right: 25px;
}

.filter-row .p-input-icon-left {
  border: none;
  margin-right:20px;
}

.filter-row .p-input-icon-left .pi {
  margin-left: 5px;
}

.filter-row .p-calendar .p-inputtext {
  height: 30px;
  font-size: 12px;
  padding: 2px 10px;
  border: none;
  width: 80px;
}

.filter-row .p-calendar .p-button {
  height: 30px;
  background: #ffffff;
  color: #000000;
  border: none;
}

.filter-row .p-multiselect {
  margin-right: 20px;
  height: 30px;
  background: #ffffff;
  color: #000000;
  border: none;
}

.filter-row .p-multiselect-label-container {
    padding-left: 25px;
}

.filter-row .p-multiselect .p-multiselect-label {
  font-size: 12px;
  padding: 2px 4px;
}

.filter-row .filer-icon {
  margin-right: 5px;
}

.filter-row .filter-button {
  height: 30px;
  font-size: 12px;
  margin-left: 20px;
  width: 120px;
}

.filter-row .reset-button {
  height: 30px;
  font-size: 12px;
  margin-left: 20px;
  background: none;
  color: #000000;
  border: solid 1px #cccccc
}

.p-multiselect-panel .p-multiselect-header {
  display: none;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  font-size: 12px;
}

.pi-tags {
  margin-left: 20px;
}