.App {
  min-width: 1350px;

}

#root {
    background-color: #efefef;
}

header {
  position: sticky;
  top: 0;
  color: #FFFFFF;
  background: #1d1d2b;
  align-items: center;
  z-index:100;
}

content {
  background: #efefef;
  align-items: center;
}

nav {
  max-width: 1200px;
  min-width: 1200px;
  margin: 0px auto;
  height: 44px;
}

#content-holder {
  width: 100%;
  margin: 0px auto;
  text-align: right;
}

.flag-icon {
  margin-right: 7px;
}

.p-rowgroup-header-name {
  font-size: 24px;
}

.ongoing-label {
  margin-right: 8px;
  color: #008013;
  font-size: 8px;
}

.event-title {
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.duration-from {
  font-size: 16px;
}

.duration-to {
  color: #cccccc;
  font-size: 12px;
}

.month {
  margin-left: 10px;
  margin-right: 10px;
  font-weight: bold;
}

.month-counter {
  color: #cccccc;
}

.title-container {
  width: 100%;
  padding-right: 10px;
}

.title-container > div {

}

.teachers-str {
  color: #cccccc;
  font-size: 10px;
  text-align: left;
}

.p-dialog .p-dialog-header {
  border-top-right-radius: 5px !important;
  border-top-left-radius: 5px !important;
}

.p-dialog .p-dialog-content:last-of-type {
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.p-avatar-group .p-avatar+.p-avatar {
  margin-left: -5px !important;
}

.p-avatar-group .p-avatar {
  font-size: 10px;
  height: 28px;
  width: 28px;
  margin: 0px;
}

.p-inputwrapper, .p-calendar, .filter-name {
    border: 1px solid #dddddd !important;
    border-radius: 2px !important;
}

.p-datatable .p-datatable-tbody > tr {
  background: none !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 0px 0px !important;
  border: none !important;
}

.p-rowgroup-header {
  font-size: 18px;
}

.p-rowgroup-header > td > span {
  padding-bottom: 5px !important;
  padding-top: 25px !important;
}

.event-table-list .p-chip {
  font-size: 9px;
  margin-bottom: 0px;
  margin-right: 3px !important;
  padding: 0px 3px;
  font-weight: bold;
}

.event-table-list .past-event,
.event-table-list .past-event span {
  color: #cccccc !important;
}

.p-datatable-scrollable .p-datatable-thead {
  display: none !important;
}

.title-container .p-tag {
  margin-left: 10px;
  font-size: 11px;
  height: 18px;
}

.duration-icon {
  width: 5px;
  margin-right: 4px;
  color: #cccccc;
}

.festival-day {
  color: #008013;
}

.duration-count {
  color: #cccccc;
  font-style: italic;
  font-size: 12px;
  margin-left: 4px;
}

.event-table-list .p-chip {
  background: none;
  border: none;
}

.SB span {
  color: #1c76c8;
}
.BL span {
  color: #1c76c8;
}
.BA span {
  color: #dfc700;
}
.LH span {
  color: #cb5600;
}
.JZ span {
  color: #cb5600;
}
.BW span {
  color: #d40000;
}
.SH span {
  color: #9c00e4;
}
.CH span {
  color: #cb5600;
}
.AF span {
  color: #dfc700;
}
.FS span {
  color: #9c00e4;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
