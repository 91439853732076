#content {
    width: 1200px;
    max-width: 1200px;
    margin: 0px auto;
}

.p-datepicker table td > span.p-highlight {
    color: #ffffff;
    background: #5c9dd9;
}

.details-dialog .grid {
    margin-top:0px;
}

.profile-card {
  /*border: 1px solid #eeeeee !important;*/
  background: #efefef;
  border-radius: 5px !important;
  padding: 8px;
  font-size: 12px;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 116px;
}

.profile-card .profile-name {
  padding-top: 10px;
}

.profile-card .profile-name span {
  text-align: center;
}

.details-dialog .duration-str {
  font-weight: bold;
  margin-right: 10px;
}

.details-dialog .map {

}

.details-dialog .p-datepicker {
  border: 1px solid #cccccc;
  border-radius: 5px;
}

.details-dialog .leaflet-container {
  border-radius: 5px;
}

.details-dialog .p-chip {
  font-size: 12px;
  font-weight: bold;
  background: none;
  border: 1px solid #000000;
}

.details-dialog .map-header {
    margin-top: 25px;
}

.details-dialog .card-container .p-chip {
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 18px;
    border: none;
    background: #dddddd;
}

.details-dialog .card-container .p-chip img {
    width: 42px;
    height: 42px;
    margin: -4px;
    margin-left: -9px;
    margin-right: 8px;
    border: 1px solid #000000;
    /*margin-left: -0.5rem;
    margin-right: 0.5rem;*/
}

.details-dialog .bands-block {
    margin-top: 20px;
}

.details-dialog .bands-block .p-chip {
    font-size: 13px;
    margin-right: 15px;
    margin-bottom: 15px;
    border-color: #cccccc;
}

.details-dialog .teachers-block {
    margin-top: 20px;
}

.details-dialog .link {
    vertical-align: middle;
}

.details-dialog .link svg {
    height: 20px;
    margin-bottom: -5px;
    margin-right: 5px;
}

.details-dialog .header {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
}

.details-dialog .description {
    padding-right: 20px;
}

.details-dialog .p-datepicker table td > span {
    width: auto;
    border-radius: 0px;
}

.details-dialog .p-datepicker table td {
    padding: 1px 0px;
}

.details-dialog .p-datepicker table td.p-datepicker-today > span {
    width: 32px;
    border-radius: 50%;
    border: 1px solid #000000;
    background: none;
    color: #555555;
}

.details-dialog .p-datepicker-prev,
.details-dialog .p-datepicker-next {
    display: none;
}

.details-dialog div.SB {
  border-color: #1c76c8;
}
.details-dialog div.BL {
  border-color: #1c76c8;
}
.details-dialog div.BA {
  border-color: #dfc700;
}
.details-dialog div.LH {
  border-color: #cb5600;
}
.details-dialog div.JZ {
  border-color: #cb5600;
}
.details-dialog div.BW {
  border-color: #d40000;
}
.details-dialog div.SH {
  border-color: #9c00e4;
}
.details-dialog div.CH {
  border-color: #cb5600;
}
.details-dialog div.AF {
  border-color: #dfc700;
}
.details-dialog div.FS {
  border-color: #9c00e4;
}

